<template>
  <v-card width="310" rounded="lg">
    <v-card-text v-if="invalidToken" class="d-flex flex-column justify-center">
      <v-icon class="icon" color="error">fas fa-exclamation-circle</v-icon>

      <p class="text-center mt-5">Link inválido.</p>

      <v-btn
        outlined
        color="primary"
        class="no-text-transform"
        to="/solicitar-email-verificacao"
      >
        Solicitar novo link
      </v-btn>
    </v-card-text>

    <v-card-text v-if="successAction" class="d-flex flex-column justify-center">
      <v-icon class="icon" color="primary">far fa-check-circle</v-icon>

      <p class="text-center mt-5">E-mail verificado.</p>

      <v-btn outlined color="primary" class="no-text-transform" to="/entrar">
        Voltar para o login
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import ToastService from "@/services/ToastService";
import AuthService from "@/services/Auth/AuthService";

export default {
  name: "EmailVerify",

  mounted: function () {
    const token = this.$route.params.token;
    if (token === undefined) {
      this.invalidToken = true;
    } else {
      this.data.token = token;
      this.handleSubmitData();
    }
  },

  data: () => ({
    data: {},
    invalidToken: false,
    successAction: false,
    loadButtonSubmit: false,
    AuthService: new AuthService("/auth"),
  }),

  methods: {
    async handleSubmitData() {
      try {
        await this.AuthService.emailVerify(`verify/email/${this.data.token}`);

        this.successAction = true;

        ToastService("E-mail verificado com sucesso", "success");
      } catch (exception) {
        this.invalidToken = true;

        ToastService(exception, "error");
      }
    },
  },
};
</script>

<style scoped>
.toolbar-title {
  margin-top: 25px;
  color: #0b6775;
  font-weight: bold;
  text-align: center;
  width: 100% !important;
}

.icon {
  font-size: 100px;
}
</style>